<style>
.current_resource {
  -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;
  animation: twinkling 1s infinite 0.3s ease-in-out alternate;
  width: 60px;
  height: 15px;
  background-color: #F54236;
  float: left;
  margin-right: 5px;
  cursor: auto;
}
@-webkit-keyframes twinkling {
  /*透明度由0到1*/
  0% {
    opacity: 0.3; /*透明度为0*/
  }
  100% {
    opacity: 1; /*透明度为1*/
  }
}
@keyframes twinkling {
  /*透明度由0到1*/
  0% {
    opacity: 0.3; /*透明度为0*/
  }
  100% {
    opacity: 1; /*透明度为1*/
  }
}
</style>

<template>
  <div>
    <div v-if="multiShow">
      <Tabs v-model="defaultTab" @on-click="changeTabs" class="text-backgroud">
        <TabPane v-for="(item,index) in themeSvgList" :key="index" :name="'tab'+index" :label="item.stationName + ' ' + ((item.floor>0)? '地上'+item.floor+'层':'地下'+item.floor+'层')">
          <div class="right m-r-10">
            <a href="javascript:void(0)" class="current_resource"></a>
            <div class="text-black inline">当前资源</div>
            <div style="clear:both"></div>
          </div>
          <div v-if="item.svg" v-html="item.svg" class="svg-station-content" style="height:500px;" :id="'svg-resource-theme_'+ index"></div>
          <div v-else class="default-content p-b-50">
            <p style="font-size: xx-large;">暂无SVG站点地图</p>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <div v-else>
      <div v-show="labelShow ">
        <div>
          <a href="javascript:void(0) " class="current_resource "></a>
          <div>当前资源</div>
          <div style="clear:both "></div>
        </div>
      </div>
      <div v-if="svgInfo.svg" v-html="svgInfo.svg" class="svg-station-content " style="height:500px;" id="svg-station-content"></div>
      <div v-else class="default-content p-b-50">
        <p style="font-size: xx-large;">暂无SVG站点地图</p>
      </div>
    </div>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import { getSkuSvgList } from '@/api/product/stationgraph'

export default {
  name: 'SvgResourceComponent',
  data () {
    return ({
      svgInfo: {
        svg: '',
        floor: null,
        stationId: 0,
        stationName: '',
        positionName: '',
        position: null
      },
      multiShow: false,
      labelShow: false,
      defaultTab: 'tab0',
      themeSvgList: [],
      existSvgZoom: [],
      deviceList: []
    })
  },
  methods: {
    bindLightBoxData (data, deviceId) {
      if (!data.svg || data.svg.length < 1) { return }

      this.multiShow = false
      this.labelShow = true
      this.svgInfo = data
      this.$nextTick(() => {
        const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
        // 设置svg呈现在画板上的尺寸
        cuurentSvgElement.setAttribute('width', '100%')
        cuurentSvgElement.setAttribute('height', '100%')
        var curMap = svgPanZoom(cuurentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        if (cuurentSvgElement.id === 'drawing') {
          // 旧版本使用path获取点位节点
          const pathNodeList = cuurentSvgElement.getElementsByTagName('path')
          if (pathNodeList.length > 0) {
            for (let i = 0; i < pathNodeList.length; i++) {
              const el = pathNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))
                if (rId === deviceId) {
                  el.setAttribute('fill', '#F54236')
                  el.setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.5s ease-in-out alternate;animation: twinkling 1s infinite 0.5s ease-in-out alternate')
                }
              }
            }
          }
        } else {
          // 旧版本使用g获取点位节点
          curMap.zoom(2)
          // 移除title标签， 改标签用于tips
          if (cuurentSvgElement.lastElementChild.lastElementChild.firstElementChild.nodeName === 'title') {
            cuurentSvgElement.lastElementChild.lastElementChild.removeChild(
              cuurentSvgElement.lastElementChild.lastElementChild.firstElementChild
            )
          }

          const gNodeList = cuurentSvgElement.getElementsByTagName('g')
          if (gNodeList.length > 0) {
            for (let i = 0; i < gNodeList.length; i++) {
              const el = gNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))
                if (rId === deviceId) {
                  el.setAttribute('fill', '#F54236')
                  for (let h = 0; h < el.children.length; h++) {
                    el.children[h].setAttribute('fill', '#F54236')
                    el.children[h].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                  }
                }
              }
            }
          }
        }
      })
    },
    bindTrainData (data) {
      if (!data.svg || data.svg.length < 1) { return }

      this.labelShow = false
      this.svgInfo = data
      this.$nextTick(() => {
        const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
        // 设置svg呈现在画板上的尺寸
        cuurentSvgElement.setAttribute('width', '100%')
        cuurentSvgElement.setAttribute('height', '100%')
        svgPanZoom(cuurentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)
      })
    },
    bindThemeData (productskuId, tagResoursArray) {
      this.deviceList = tagResoursArray
      this.existSvgZoom = []
      this.themeSvgList = []
      this.defaultTab = 'tab0'
      this.multiShow = true
      this.labelShow = true

      getSkuSvgList({ skuId: productskuId }).then(res => {
        this.themeSvgList = res
        this.changeTabs(this.defaultTab)
      })
    },
    changeTabs (name) {
      this.defaultTab = name
      const currentSvgId = 'svg-resource-theme_' + name.substr(3)
      this.beginSvgPanZoom(currentSvgId)
    },
    beginSvgPanZoom (svgId) {
      if (this.existSvgZoom.indexOf(svgId) > -1) {
        return
      } else {
        this.existSvgZoom.push(svgId)
      }

      this.$nextTick(() => {
        const currentSvgElement = document.getElementById(svgId).firstElementChild
        // 设置svg呈现在画板上的尺寸
        currentSvgElement.setAttribute('width', '100%')
        currentSvgElement.setAttribute('height', '100%')

        var curMap = svgPanZoom(currentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        if (currentSvgElement.id === '') { // 新版地图
          curMap.zoom(1.5)
          // 移除title标签， 改标签用于tips
          if (currentSvgElement.lastElementChild.lastElementChild.firstElementChild.nodeName === 'title') {
            currentSvgElement.lastElementChild.lastElementChild.removeChild(
              currentSvgElement.lastElementChild.lastElementChild.firstElementChild
            )
          }

          const gNodeList = currentSvgElement.getElementsByTagName('g')
          if (gNodeList.length > 0 && this.deviceList.length > 0) {
            for (let i = 0; i < gNodeList.length; i++) {
              const el = gNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))

                if (this.deviceList.indexOf(rId) > -1) {
                  for (let h = 0; h < el.children.length; h++) {
                    // 单独处理异性墙贴
                    if (el.getAttribute('data-devicemodelid') === '110') {
                      if (el.children[h].nodeName === 'g') { // 多个元素组，合并组合而成的墙贴
                        for (let gIndex = 0; gIndex < el.children[h].children.length; gIndex++) {
                          el.children[h].children[gIndex].setAttribute('stroke-width', '1')
                          el.children[h].children[gIndex].setAttribute('stroke', '#F54236')
                          el.children[h].children[gIndex].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                        }
                      } else {
                        el.children[h].setAttribute('stroke', '#F54236')
                        el.children[h].setAttribute('stroke-width', '1')
                        el.children[h].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                      }
                    } else {
                      el.children[h].setAttribute('fill', '#F54236')
                      el.children[h].setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                    }
                  }
                }
              }
            }
          }
        } else {
          const pathNodeList = document.getElementsByTagName('path')
          if (pathNodeList.length > 0) {
            for (let i = 0; i < pathNodeList.length; i++) {
              const el = pathNodeList[i]
              if (el.hasAttribute('data-id')) {
                const rId = parseInt(el.getAttribute('data-id'))
                if (this.deviceList.indexOf(rId) > -1) {
                  el.setAttribute('fill', '#F54236')
                  el.setAttribute('style', ' -webkit-animation: twinkling 1s infinite 0.3s ease-in-out alternate;animation: twinkling 1s infinite 0.3s ease-in-out alternate')
                }
              }
            }
          }
        }
      })
    }
  }
}
</script>
