import request from '@/utils/requestV2'
const qs = require('qs')

// 获取常规媒体
export function getCommonPage (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/getcommonpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取主题媒体
export function getFixPage (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/getfixpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取套装媒体
export function getCustomPage (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/getcustompage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 新增常规媒体
export function addCommon (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/addcommon',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 编辑常规媒体
export function editCommon (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/editcommon',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 新增主题媒体
export function addFix (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/addfix',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 编辑主题媒体
export function editFix (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/editfix',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取全部媒体
export function getAllSkuPage (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/gettotalpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 新增套装媒体
export function addCustom (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/addcustom',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更新套装媒体
export function editCustom (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/editcustom',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取产品示意图
export function getPhotoBySkuid (params) {
  return request.get('/ooh-product/v1/productskuedit/getphotobyskuid', { params })
}

// 删除产品示意图
export function deletePhotoById (params) {
  return request.get('/ooh-product/v1/productskuedit/deletephotobyid', { params })
}

/**
 * 更新sku服务费刊例价及结算价
 * @param {Object} data
 * @returns
 */
export function updateProducePrice (data) {
  return request({
    url: '/ooh-product/v1/productskuedit/updateproduceprice',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}

// 获取产品资源类型
export function getProductResourcetypeIdList (params) {
  return request.get('/ooh-product/v1/productskuedit/getProductResourcetypeIdList', { params })
}

/**
 * 获取单品下的服务费字典
 * @param {Object} data
 * @returns
 */
export function getServiceItemBySku (data) {
  return request.post('/ooh-product/v1/productskuedit/getServiceItemBySku', qs.stringify(data))
}

/**
 * 更新sku服务费字典
 * @param {Object} data
 * @returns
 */
export function updateServiceItem (data) {
  return request.post('/ooh-product/v1/productskuedit/updateServiceItem', qs.stringify(data))
}
