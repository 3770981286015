<template>
  <div>
    <h4 class="workplatform-title m-t-10 m-b-10">媒体属性</h4>
    <Row>
      <i-col span="8">
        <Label class="title">单品名称</Label>
        <span class="text-orange">{{themeModel.productskuName}}</span>
      </i-col>
      <i-col span="8">
        <Label class="title">所属产品</Label>
        <span>{{themeModel.productName}}</span>
      </i-col>
      <i-col span="8">
        <Label class="title">售卖刊例</Label>
        <span>{{formatMoney(themeModel.usePrice)}} /天</span>
      </i-col>
      <i-col span="8" class="p-t-5">
        <Label class="title">可售面积</Label>
        <span>{{themeModel.totalArea}} 平方米</span>
      </i-col>
    </Row>

    <h4 class="workplatform-title m-t-10 m-b-10">资源清单</h4>
    <Row v-for="(item,index) in list" :key="index" class="m-b-5">
      <i-col span="24">
        <p class="p-b-5">
          <Label class="title">所在站点</Label><span>{{item.stationName}} (楼层 {{item.floor}}层)</span>
        </p>
        <p class="p-b-5">
          <Label class="title">包含资源</Label>
          <span v-for="(res,idx) in item.productResourceBeanList" :key="idx">{{res.resourcetypeName}} {{res.code}} <span v-if="idx<item.productResourceBeanList.length-1">、</span> </span>
        </p>
      </i-col>

    </Row>

  </div>
</template>

<script>
import { getproductfixfile } from '@/api/product/productfix'
import { getProductskuFixResource } from '@/api/product/productsku'
import { toMoney } from '@/utils/wnumb_own'

export default {
  name: 'ThemeAttributeComponent',
  props: {
    resourceId: {
      type: Number,
      required: true
    },
    svgData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      themeModel: {
        usePrice: 0,
        productName: '',
        productskuName: '',
        stationLevelName: '',
        totalArea: 0
      },
      list: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const postData = {
        fixProductskuId: this.resourceId,
        fixskuId: this.resourceId
      }
      getproductfixfile(postData).then((result) => {
        this.themeModel.totalArea = result.totalArea

        this.$emit('up-data', result.mrLocation, result.mimeType)
      })

      getProductskuFixResource(postData).then((result) => {
        this.themeModel.usePrice = result.usePrice
        this.themeModel.productName = result.productName
        this.themeModel.productskuName = result.productskuName
        this.themeModel.stationLevelName = result.stationLevelName
        this.list = result.productskufixResourceDescBeanList

        this.$emit('update:svgData', result)
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
