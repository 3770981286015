<template>
  <div>
    <theme-attribute-component :resourceId="resourceId" :svgData.sync="svgData" @up-data="imgDataChange"/>

    <h4 class="workplatform-title m-t-10 m-b-10">图例描述</h4>
    <Tabs v-model="curTab">
      <TabPane label="位置展示" name="name1">
        <resource-svg-component style="background:white;color: #2b85e4;" id='svgResource' ref="resourceSvg"></resource-svg-component>
      </TabPane>
      <TabPane label="MR源稿" name="name2">
        <Row class="text-right p-b-10">
          <i-col span="24">
            <Select v-if="isManage" size="small" v-model="chooseVersion" style="width:200px" class="m-r-5" @on-change="hadnleChangeVersion">
              <Option
                v-for="item in MrHistoryList"
                :value="item.id"
                :key="item.id"
              >{{item.createTime}}</Option>
            </Select>
            <template v-if="MrHistoryList.length>0">
              <i-button type="primary" class="m-r-5" size="small"  @click="handleSettingVersion">设置为生效版本</i-button>
              <i-button type="success" class="m-r-5" size="small"  @click="handleDownload">下载MR源稿（SVG文件）</i-button>
            </template>
          </i-col>
        </Row>
        <template v-if="mrHtml" >
          <div v-html="mrHtml" class="svg-station-content " style="height:500px;" id="svg-mr-content"></div>
        </template>
        <template v-else-if="!showMRsvg && MrHistoryList.length>0">
            <div id='MRpicture'  style="overflow-x:auto; height:500px; text-align:center; cursor: pointer;"  @click="fullScreen"  >
              <img style="height:99%;" :src="mrUrl"/>
            </div>
        </template>
        <div v-else class="default-content">
            <p style="font-size: xx-large;">抱歉，无法加载MR源稿！</p>
        </div>
      </TabPane>
      <TabPane label="示意图" name="name3">
          <Row>
            <i-col span="8" v-for="(img,index) in resourceImgs" :key="index" class="p-b-20">
              <div v-viewer class="images clearfix">
                <img :src="img.ossLocation" style="cursor: pointer;" class="image" width="250">
              </div>
            </i-col>
          </Row>
          <p v-if="resourceImgs.length===0" class="text-center p-t-20 text-16 text-gray" >暂无数据</p>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import ResourceSvgComponent from '@/components/svg/SvgResource'
import ThemeAttributeComponent from './ThemeAttribute'
import axios from 'axios'
import svgPanZoom from 'svg-pan-zoom'
import { downloadFileRequest } from '@/utils/download'
import { getMRlist, changeMR } from '@/api/product/productfix'
import { getPhotoBySkuid } from '@/api/product/productskuedit'

export default {
  name: 'ThemeInfoComponet',
  props: {
    resourceId: {
      type: Number,
      required: true
    },
    isManage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ResourceSvgComponent,
    ThemeAttributeComponent
  },
  data () {
    return {
      curTab: 'name1',
      svgData: {},
      mrHtml: '',
      mrUrl: '',
      resourceName: '',
      showMRsvg: false,

      MrHistoryList: [],
      chooseVersion: null,
      resourceImgs: [] // 图片集合
    }
  },
  created () {
    if (this.isManage) {
      this.loadMRlist()
    }
    this.loadResourceImg()
  },
  methods: {
    imgDataChange (mrUrl, mimeType) {
      this.mrUrl = mrUrl
      this.mrHtml = ''
      this.showMRsvg = mimeType === 'image/svg+xml'

      if (this.showMRsvg) {
        axios.get(mrUrl).then(res => {
          this.mrHtml = res.data
          this.$nextTick(() => {
            const cuurentSvgElement = document.getElementById('svg-mr-content').firstElementChild
            // 设置svg呈现在画板上的尺寸
            cuurentSvgElement.setAttribute('width', '100%')
            cuurentSvgElement.setAttribute('height', '100%')
            svgPanZoom(cuurentSvgElement, {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              center: true
            })
          })
        })
      }
    },
    loadMRlist () {
      getMRlist({ skuId: this.resourceId }).then(res => {
        this.MrHistoryList = res
      })
    },
    loadResourceImg () {
      getPhotoBySkuid({ skuId: this.resourceId }).then(res => {
        this.resourceImgs = res
      })
    },
    handleDownload () {
      const fileType = this.showMRsvg ? 'svg' : 'jpg'

      downloadFileRequest(this.mrUrl, null, '(' + this.resourceName + ')MR源稿.' + fileType)
    },
    fullScreen () {
      var elem = document.getElementById('MRpicture')

      requestFullScreen(elem)
      function requestFullScreen (element) {
        var requestMethod =
          element.requestFullScreen ||
          element.webkitRequestFullScreen ||
          element.mozRequestFullScreen ||
          element.msRequestFullScreen
        if (requestMethod) {
          requestMethod.call(element)
        }
      }
    },
    hadnleChangeVersion () {
      const versionBean = this.MrHistoryList.find(x => x.id === this.chooseVersion)

      this.imgDataChange(versionBean.mrLocation, versionBean.mimeType)
    },
    handleSettingVersion () {
      const postData = {
        fileId: this.chooseVersion,
        skuId: this.resourceId
      }

      changeMR(postData).then(res => {
        this.$Notice.success({ desc: 'MR版本设置成功！' })
      })
    }
  },
  watch: {
    svgData (val) {
      this.resourceName = val.productskuName

      let tagResoursArray = []
      val.productskufixResourceDescBeanList.forEach(element => {
        tagResoursArray = tagResoursArray.concat(element.productResourceBeanList.map(x => x.deviceId))
      })
      this.$refs.resourceSvg.bindThemeData(val.productskuId, tagResoursArray)
    }
  }
}
</script>
